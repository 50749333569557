<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<v-row dense>
						<v-col cols="3">
							<pui-text-field
								:id="`variable-portauthorityconfig`"
								v-model="model.variable"
								:label="$t('portauthorityconfig.variable')"
								:disabled="!isCreatingElement"
								required
								toplabel
								maxlength="50"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="6">
							<pui-text-area
								:id="`value-portauthorityconfig`"
								v-model="model.value"
								:label="$t('portauthorityconfig.value')"
								:disabled="formDisabled"
								toplabel
							></pui-text-area>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns :formDisabled="formDisabled" :saveDisabled="saving" :save="save" :back="back"></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'portauthorityconfig-form',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'portauthorityconfig',
			actions: []
		};
	},
	methods: {
		afterGetData() {
			if (this.isCreatingElement) {
				this.model.portauthorityid = this.$store.getters.getPortAuthorityId;
			}
		}
	},
	computed: {},
	created() {}
};
</script>
